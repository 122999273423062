import { z } from 'zod';
import { OrganizationRoles, Organizations, OrganizationInvitations, MagicLinks, } from '../db-entries/index.js';
import { userInfoGuard } from './user.js';
export const organizationRoleWithScopesGuard = OrganizationRoles.guard.extend({
    scopes: z
        .object({
        id: z.string(),
        name: z.string(),
    })
        .array(),
});
const organizationRoleEntityGuard = z.object({
    id: z.string(),
    name: z.string(),
});
export const organizationWithOrganizationRolesGuard = Organizations.guard.extend({
    organizationRoles: organizationRoleEntityGuard.array(),
});
export const userWithOrganizationRolesGuard = userInfoGuard.extend({
    organizationRoles: organizationRoleEntityGuard.array(),
});
export const organizationInvitationEntityGuard = OrganizationInvitations.guard.extend({
    magicLink: MagicLinks.guard,
    organizationRoles: organizationRoleEntityGuard.array(),
});
