// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    token: z.string().min(1).max(32),
    createdAt: z.number().optional(),
    consumedAt: z.number().nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    token: z.string().min(1).max(32),
    createdAt: z.number(),
    consumedAt: z.number().nullable(),
});
export const MagicLinks = Object.freeze({
    table: 'magic_links',
    tableSingular: 'magic_link',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        token: 'token',
        createdAt: 'created_at',
        consumedAt: 'consumed_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'token',
        'createdAt',
        'consumedAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
